// extracted by mini-css-extract-plugin
export var communityBox = "community-module--community-box--d98ff";
export var communityLink = "community-module--community-link--7a61e";
export var discord = "community-module--discord--eab7b";
export var facebook = "community-module--facebook--c96b7";
export var github = "community-module--github--5b696";
export var highpointers = "community-module--highpointers--ee36b";
export var logo = "community-module--logo--d78ce";
export var podcast = "community-module--podcast--d2bb6";
export var reddit = "community-module--reddit--8b9c8";
export var video = "community-module--video--5ab35";